import React from 'react'

const Dases = () => {
    return (
        <>

            <div className='mission'>
                <h1>  Specialization</h1>

                <div className='mission2'>

                    <div className='special'>
                        <p>* Breast Cancer & Benign Breast Diseases</p>
                        <p>*  Diabetic Foot Problems </p>
                        <p>* Adrenal Tumours</p>
                    </div>

                    <div className='special'>
                        <p>   * Thyroid Diseases, Goiters & Thyroid Cancer </p>

                        <p>   * Parathyroid & Metabolic Bone Diseases</p>

                        <p>    * Pancreatic Neuro-Endocrine Tumours & Carcinoid tumours</p>
                    </div>
                </div>


            </div>

            <div className='mission'>
                <h1>  clinic</h1>
                <h2> Breast & Endocrine Clinic</h2>
                <h2>Add. Tilak Marg, Dali Bagh, Lucknow</h2>
                <h2>Evening Clinic: (6.00 - 8.00 pm) Clinic Helpline No +91-7800955889  </h2>
                <br />
                <h1>Senior  Consultant</h1>

                <div className='mission2'>

                    <div className='special'>
                        <p> *Midland Hospital & Research Centre, Mahanagar Lucknow</p>
                        <p> *Tender Palm Super-Speciality Hospital Lucknow</p>

                    </div>

                    <div className='special'>
                        <p> *Allyantra Medicity, Lucknow</p>
                        <p> * Shekhar Hospital Indira Nagar Lucknow</p>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Dases


